var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-update-agency-revenue","cancel-variant":"outline-secondary","ok-title":_vm.$t('Update'),"cancel-title":_vm.$t('Close'),"size":"lg","no-enforce-focus":"","ok-variant":"primary","centered":"","modal-class":"modal-primary","title":_vm.$t('Update Agency Revenue Setting')},on:{"ok":_vm.confirmUpdate,"hidden":function($event){_vm.agency == null}}},[_c('validation-observer',{ref:"updateAgencyModal"},[_c('b-form',{attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"from"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("From"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"from","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"from","type":"number","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.agency.from),callback:function ($$v) {_vm.$set(_vm.agency, "from", $$v)},expression:"agency.from"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"to"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("To"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"to","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"to","type":"number","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.agency.to),callback:function ($$v) {_vm.$set(_vm.agency, "to", $$v)},expression:"agency.to"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"c"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Min_user"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"min_user","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"min_user","type":"number","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.agency.min_user),callback:function ($$v) {_vm.$set(_vm.agency, "min_user", $$v)},expression:"agency.min_user"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"discount"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Discount"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"discount","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"discount","type":"number","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.agency.discount),callback:function ($$v) {_vm.$set(_vm.agency, "discount", $$v)},expression:"agency.discount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }