<template>
    <div>
        <b-modal id="modal-update-agency-revenue" cancel-variant="outline-secondary" :ok-title="$t('Update')"
            :cancel-title="$t('Close')" size="lg" no-enforce-focus ok-variant="primary" centered modal-class="modal-primary"
            :title="$t('Update Agency Revenue Setting')" @ok="confirmUpdate" @hidden="agency == null">
            <!-- form -->
            <validation-observer ref="updateAgencyModal">
                <b-form method="POST" @submit.prevent="validationForm">
                    <b-form-group label-for="from">
                        <label class="mb-1">{{ $t("From") }} <span class="text-danger">*</span></label>
                        <validation-provider #default="{ errors }" name="from" rules="required|min:1">
                            <b-form-input id="from" type="number" v-model="agency.from"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label-for="to">
                        <label class="mb-1">{{ $t("To") }} <span class="text-danger">*</span></label>
                        <validation-provider #default="{ errors }" name="to" rules="required|min:1">
                            <b-form-input id="to" type="number" v-model="agency.to"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label-for="c">
                        <label class="mb-1">{{ $t("Min_user") }} <span class="text-danger">*</span></label>
                        <validation-provider #default="{ errors }" name="min_user" rules="required|min:1">
                            <b-form-input id="min_user" type="number" v-model="agency.min_user"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label-for="discount">
                        <label class="mb-1">{{ $t("Discount") }} <span class="text-danger">*</span></label>
                        <validation-provider #default="{ errors }" name="discount" rules="required|min:1">
                            <b-form-input id="discount" type="number" v-model="agency.discount"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>
  
<script>
import {
    ValidationProvider,
    ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormRadio,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    VBModal,
    BInputGroupAppend,
    BFormTextarea,
    BBadge,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { numberFormat } from "@core/utils/filter";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Editor from "@tinymce/tinymce-vue";
export default {
    components: {
        BLink,
        BRow,
        BCol,
        BTabs,
        BTab,
        BImg,
        BForm,
        BFormGroup,
        BFormInput,
        BFormFile,
        BFormRadio,
        BButton,
        BInputGroup,
        BInputGroupPrepend,
        BModal,
        BFormTextarea,
        BBadge,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        Editor,
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    props: {
        agencyRevenueDetail: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            agency: {
                id: "",
                from: "",
                to: "",
                min_user: "",
                discount: ""
            },
        };
    },
    watch: {
        agencyRevenueDetail: {
            handler(newVal) {
                if (newVal) {
                    this.agency = {
                        id: newVal.id,
                        from: newVal.from,
                        to: newVal.to,
                        min_user: newVal.min_user,
                        discount: newVal.discount,
                    };
                }
            },
            deep: true,
        },
    },
    methods: {
        validationForm() {
            this.$refs.updateAgencyModal.validate().then((success) => {
                if (success) {
                    this.updateAgency(this.agency);
                }
            });
        },
        async updateAgency(agencyData) {
            // agencyData.from = numberFormat(agencyData.from);
            // agencyData.to = numberFormat(agencyData.to);
            await this.$store
                .dispatch("marketing/updateAgencyRevenue", agencyData)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.code == "00") {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message || "success",
                                    icon: "CheckIcon",
                                    variant: "success",
                                },
                            });
                            this.$emit("refetch-data");
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.$bvModal.hide("modal-update-agency-revenue");
                                    this.resetModal();
                                }, "100");
                            });
                            this.$emit("refetch-data");
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: "AlertTriangleIcon",
                                    variant: "danger",
                                },
                            });
                        }
                    }
                });
        },
        resetModal() {
            this.agency = {
                id: "",
                from: "",
                to: "",
                min_user: "",
                discount: ""
            };
            this.$emit("update: agencyRevenueDetail", null);
        },
        confirmUpdate(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.validationForm();
        },
    },
    setup() {
        
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>